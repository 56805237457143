import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import { TailSpin } from  'react-loader-spinner';
import Markdown from 'marked-react';
import Skeleton from 'react-loading-skeleton';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './wiki.css';
import 'react-loading-skeleton/dist/skeleton.css';

const Wiki = () => {

    //Set variables
    const environment = process.env.REACT_APP_APP_URL;
    const environmentStaging = process.env.REACT_APP_APP_URL;
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [wikis, setWikis] = useState([]);
    const [wiki, setWiki] = useState();

    const getWikis = async () => {
        try {
            const response = await api.get('/wikis');
            if (response.data) {
                const result = response.data.sort((a, b) => (a.order > b.order) ? 1 : -1);
                setWikis(result);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
        }
    }

    const getWiki = async (id) => {
        try {
            const response = await api.get('/wikis/' + id);
            if (response.data) {
                setWiki(response.data);
            }
        } catch (error) {}
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getWikis();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/wiki" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Wiki" />
            {
                wiki ?
                <div className="wiki-item">
                    <div className="wiki-content">
                    <h2 className="title">{wiki.onderwerp}</h2>
                        {
                        wiki.sub_onderwerp.map((item, index) =>  {
                            return <div key={index} id={item.anchor} className="subject">
                                <h2>{index + 1 + '. '}{item.naam}</h2>
                                {
                                    item.content.map((contentItem, index) =>  {
                                        return <div key={index}>
                                        <Markdown>{contentItem.tekst}</Markdown>
                                        {
                                            contentItem.afbeelding ?
                                            <img src={environment + contentItem.afbeelding.url} />
                                            :
                                            null
                                        }
                                        </div>
                                    })
                                }
                            </div>
                        })
                        }
                    </div>
                    <div className="toc">
                        <div className="toc-block">
                            <button onClick={() => setWiki()}><Icon.ArrowLeftCircle />Terug naar overzicht</button>
                        {
                            wiki.sub_onderwerp.map((item, index) =>  {
                                return <a key={index} href={'#' + item.anchor}>{index + 1 + '. '}{item.naam}</a>
                            })
                        }
                        </div>
                    </div>    
                </div>
                :
                <div className="wiki-subjects">
                    {
                        wikis.length > 0 ?
                        wikis.map((item, index) =>  {
                            const IconComponent = Icon[item.icoon];
                            return <button key={index} onClick={() => getWiki(item.id)}><div className="button-content"><IconComponent /><span>{item.onderwerp}</span></div></button>
                        })
                        :
                        <>
                        <Skeleton height={180} width={375} style={{marginBottom:20}} />
                        <Skeleton height={180} width={375} style={{marginBottom:20}} />
                        <Skeleton height={180} width={375} style={{marginBottom:20}} />
                        </>
                    }
                </div>
            }
            


            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/wiki" />
            :
            null
        }
    <img src={svzArtwork} className="artwork" />    
    </div>
    );
};
export default Wiki;