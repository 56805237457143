import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, handleDownloadPrikbord, getFileContentsPrikbord } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { confirmAlert } from 'react-confirm-alert';
import { Tooltip } from 'react-tooltip';
import { TailSpin } from  'react-loader-spinner';
import Select from 'react-select';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './prikbord.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-tooltip/dist/react-tooltip.css';

const PrikbordItem = () => {

    //Set variables
    const { uuid } = useParams();
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const environment = process.env.REACT_APP_APP_URL;
    const [changeItem, setChangeItem] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [title, setTitle] = useState('');
    const [date, setDate] = useState('');
    const [createdBy, setCreatedBy] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState('');
    const [newFile, setNewFile] = useState('');
    const [iframeUrl, setIframeUrl] = useState('');
    const [logs, setLogs] = useState([]);
    const [modifiedMedewerkers, setModifiedMedewerkers] = useState([]);
    const [medewerkerOptions, setMedewerkerOptions] = useState([]);
    const [changingMedewerkers, setChangingMedewerkers] = useState(false);
    const [gelezenBegrepen, setGelezenBegrepen] = useState(false);
    const [owner, setOwner] = useState(false);
    const [changeAble, setChangeAble] = useState(false);
    const [complete, setComplete] = useState(false);
    const [sendingReminder, setSendingReminder] = useState(false);

    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };    

    //Function to get declaraties
    const getPrikbordItem = async () => {
        try {
            const response = await api.get('/prikbord/' + uuid);
            if (response.data) {
                setTitle(response.data.titel);
                setDate(response.data.datum);
                setCreatedBy(response.data.aangemaakt_door);
                setDescription(response.data.omschrijving);
                setFile(response.data.bestand);
                setGelezenBegrepen(response.data.gelezen_begrepen);
                setOwner(response.data.owner);
                setChangeAble(response.data.changeable);
                setComplete(response.data.complete);
                if (response.data.owner) {
                    let medewerkersToModify = [];
                    let medewerkersToExclude = [];
                    setLogs(response.data.logs);
                    response.data.logs.map((item, index) =>  {
                        const obj = {
                            value: item.medewerker.id,
                            label: item.medewerker.naam
                        };
                        if (!item.gelezen_begrepen) {
                            medewerkersToModify.push(obj);
                        } else {
                            medewerkersToExclude.push(item.medewerker.id);
                        }
                    })
                    setModifiedMedewerkers(medewerkersToModify);
                    getMedewerkers(medewerkersToExclude);
                    setIsSaving(false);
                    setChangeItem(false);
                }
            }
        } catch (error) {
            notifyError(error.response.data.error);
        }
    }

    const itemSeen = async () => {
        try {
            const response = await api.post('/prikbord/seen/' + uuid);
            if (response.data) {
                setGelezenBegrepen(response.data.gelezen_begrepen);
                notifySuccess('Bijgewerkt en opgeslagen');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deletePrikbordItem = async () => {
        try {
            const response = await api.post('/prikbord/delete/' + uuid);
            if (response.data) {
                notifySuccess('Prikborditem is verwijderd');
                navigate('/prikbord');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const sendReminder = async () => {
        setSendingReminder(true);
        try {
            const response = await api.post('/prikbord/send-reminder/' + uuid);
            if (response.data) {
                setSendingReminder(false);
                notifySuccess(response.data.message);
            }
        } catch (error) {
            console.log(error);
            setSendingReminder(false);
        }
    }

    const confirmDeleteItem = () => {
        confirmAlert({
            title: 'Hiermee wordt het prikborditem en de logs definitief verwijderd',
            message: 'Wil je doorgaan?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => deletePrikbordItem()
              },
              {
                label: 'Nee'
              }
            ]
          });
    }

    const modifyMedewerkers = () => {
        setChangingMedewerkers(true);
    }

    //Function to get declaraties
    const getMedewerkers = async (exclude) => {
        try {
            const response = await api.post('/prikbord/medewerkers');
            if (response.data) {
                const filteredArray = response.data.filter(item => !exclude.includes(item.value));
                setMedewerkerOptions(filteredArray);
            }
        } catch (error) {
            notifyError(error.response.data.error);
        }
    }
    const handleChange = (index, value) => {
        const current = [...modifiedMedewerkers];
        current[index] = value;
        setModifiedMedewerkers(current);
    }
    const handleMedewerkers = (type, index) => {
        const current = [...modifiedMedewerkers];
        if (type === 'add') {
            current.push({value: '', label: ''});
        } else {
            current.splice(index, 1);
        }
        setModifiedMedewerkers(current);
    }

    const savePrikbord = async () => {
        let newLogs = [];
        modifiedMedewerkers.map((item, index) =>  {
            if (item.value) {
                newLogs.push({
                    medewerker: {id: item.value},
                    gelezen_begrepen: null
                });
            }
        });
        logs.map((item, index) =>  {
            if (item.gelezen_begrepen) {
                newLogs.push({
                    medewerker: {id: item.medewerker.id},
                    gelezen_begrepen: item.gelezen_begrepen
                });
            }
        })
        try {
            const response = await api.post('/prikbord/modify-medewerkers/' + uuid, {
                data: {
                    logs: newLogs
                }
            });
            if (response.data) {
                notifySuccess('Medewerkers zijn gewijzigd');
                setChangingMedewerkers(false);
                getPrikbordItem();
            }
        } catch (error) {
            notifyError(error.response.data.error);
        }
    }

    const uploadDocument = async () => {
        var formData = new FormData();
        formData.append("files", newFile);
        if (newFile.size > 52428800) {
            return notifyError('De maximale bestandsgrootte van de bijlage is 50MB');
        }
        try {
            const response = await api.post('/prikbord/upload-file', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                return response.data.filenames[0];
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer eerst even opnieuw in te loggen.');
            }
        }
    }

    const savePrikbordInfo = async (event) => {
        event.preventDefault();
        setIsSaving(true);
        try {
            const response = await api.post('/prikbord/modify-prikbordinfo/' + uuid, {
                data: {
                    title: title,
                    description: description,
                    file: newFile ? await uploadDocument() : file ? file : ''
                }
            });
            if (response.data) {
                notifySuccess('Prikborditem is opgeslagen');
                getPrikbordItem();
            }
        } catch (error) {
            notifyError(error.response.data.error);
        }
    }

    const handleFileContents = async (file) => {
        const fileUrl = await getFileContentsPrikbord(file);
        setIframeUrl(fileUrl);
    }
    
    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getPrikbordItem();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/prikbord" />
        <div className="dashboard-content">
            {title ? <DashboardHeader title={title} /> : null }
            {
                title ?
                <div className="prikbord-actions">
                    {owner && !changeItem ? <button id="change-prikbord" className="button change" disabled={changeAble ? false : true} onClick={() => setChangeItem(true)}>Wijzigen</button> : null}
                    {
                        owner && !changeAble ?
                        <Tooltip
                            anchorId={'change-prikbord'}
                            place="bottom"
                            content="Kan niet meer gewijzigd worden omdat minimaal 1 persoon al heeft gelezen en begrepen"
                        />
                        :
                        null
                    }
                    {owner ? <button className="button delete" onClick={confirmDeleteItem}><span>Verwijderen</span><Icon.Trash3 /></button> : null}
                    <button onClick={() => navigate('/prikbord')} className="button red">Terug naar overzicht</button>
                </div>
                :
                null
            }
            {
                date && createdBy ?
                <div className="subheader">
                    <p>{moment(date).format('DD-MM-YYYY')}</p>
                    { !owner ? <span>door {createdBy}</span> : null }
                </div>
                :
                null
            }
            {
                description ?
                <div className="prikbord-columns">
                    <div className="column">
                        <div className="column-content">
                            {
                                owner && changeItem ?
                                <form id="create-prikbord" onSubmit={savePrikbordInfo}>
                                    <input type="text" placeholder="Titel" className="form-field" value={title} onChange={(e) => setTitle(e.target.value)} required disabled={isSaving} />
                                    <textarea placeholder="Omschrijving" className="form-field" rows="10" value={description} onChange={(e) => setDescription(e.target.value)} required disabled={isSaving}></textarea>
                                    {
                                        file ?
                                        <div className="current-file">
                                            <Icon.Trash3Fill color="#cb0a32" onClick={() => setFile('')} className="delete" />
                                            <p>{file}</p>
                                        </div>
                                        :
                                        null
                                    }
                                    
                                    <div className="bestand">
                                        <span className="label">Bestand toevoegen (.JPG, .PNG, .PDF)</span>
                                        <input type="file" name="bestand" id="file-upload" placeholder="Bestand" accept="image/jpeg,image/png,application/pdf,image/x-eps" onChange={(e) => setNewFile(e.target.files[0])} className="form-field file" disabled={isSaving} />
                                    </div>
                                    {
                                        isSaving ?
                                        <TailSpin
                                            height="30"
                                            width="30"
                                            color="#001c43"
                                            ariaLabel="tail-spin-loading"
                                            radius="1"
                                            wrapperStyle={{}}
                                            wrapperClass=""
                                            visible={true}
                                        />
                                        :
                                        <input type="submit" value="Prikborditem bijwerken" className="create-prikbord" disabled={isSaving} />
                                    }
                                    </form>
                                    :
                                    <p className="description">{description}</p>
                            }
                            {
                                iframeUrl ?
                                <>
                                {
                                    iframeUrl.type.includes('image') ?
                                    <img src={iframeUrl.url} className="iframe-image" />
                                    :
                                    <div className="a4-aspect-ratio">
                                        <iframe src={`${iframeUrl.url}#view=fitH`} title="view PDF" />
                                    </div>
                                }
                                </>
                                
                                
                                :
                                null
                            }
                            {
                                !owner ?
                                <div className="column-footer">
                                    {
                                        gelezenBegrepen ?
                                        <p style={{color:'#00d100',fontStyle:'italic',fontFamily:'AvertaRegular'}}>Gelezen en begrepen op {moment(gelezenBegrepen).format('DD-MM-YYYY HH:mm')}</p>
                                        :
                                        <>
                                        {
                                            file && iframeUrl ?
                                            <button className="button green" onClick={itemSeen}>Gelezen en begrepen</button>
                                            :
                                            null
                                        }
                                        {
                                            !file ?
                                            <button className="button green" onClick={itemSeen}>Gelezen en begrepen</button>
                                            :
                                            null
                                        }
                                        </>
                                        
                                    }
                                </div>
                                :
                                null
                            }
                            {
                                file && !changeItem && !iframeUrl ?
                                <button className="button download" onClick={() => handleFileContents(file)}><Icon.Download /><span>Bekijk bijlage</span></button>
                                :
                                null
                            }
                        </div>
                    </div>
                    {
                        owner ?
                        <div className="column">
                            <div className="column-content">
                                <h3>Gelezen en begrepen:</h3>
                                {
                                    !changingMedewerkers ?
                                    <div className="logs">
                                    {
                                        logs.map((item, index) =>  {
                                            return (
                                                <div key={index} className={'log-item ' + (item.gelezen_begrepen ? 'seen' : '')}>
                                                    <p>{item.medewerker.naam}</p>
                                                    {item.gelezen_begrepen ? <p>{moment(item.gelezen_begrepen).format('DD-MM-YYYY HH:mm')}</p> : <p style={{fontSize:13}}>Nog niet gelezen en begrepen</p>}
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                    :
                                    null
                                }
                                
                                {
                                !complete && !changingMedewerkers ?
                                    <button className="button reminder" onClick={() => sendReminder()} disabled={sendingReminder}>
                                        {
                                            sendingReminder ?
                                            <TailSpin
                                                height="20"
                                                width="20"
                                                color="#CB0A32"
                                                ariaLabel="tail-spin-loading"
                                                radius="1"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                visible={true}
                                            />
                                            :
                                            <Icon.Envelope />
                                        }
                                        <span>{sendingReminder ? 'Reminder aan het versturen...' : 'Verstuur reminder'}</span>
                                    </button>
                                    :
                                    null
                                }
                                {
                                    !changingMedewerkers ?
                                    <button onClick={modifyMedewerkers} className="button red modify">Medewerkers wijzigen</button>
                                    :
                                    <>
                                    {
                                        modifiedMedewerkers.map((item, index) =>  {
                                            return (
                                                <div className="medewerker-row">
                                                <div className="field">
                                                    <Select
                                                        key={index} 
                                                        options={medewerkerOptions} 
                                                        className={'select-option'} 
                                                        placeholder="Medewerker" 
                                                        value={item} 
                                                        onChange={(e) => handleChange(index, e)}
                                                    />
                                                </div>
                                                <div className="buttons">
                                                    {
                                                        modifiedMedewerkers.length > 1 ?
                                                        <span className="remove" onClick={() => handleMedewerkers('remove', index)}><Icon.DashCircleFill /></span>
                                                        :
                                                        null
                                                    }
                                                    <span className="add" onClick={() => handleMedewerkers('add', index)}><Icon.PlusCircleFill /></span>
                                                </div>
                                                </div>
                                            )
                                        })
                                    }
                                    <button onClick={savePrikbord} className="button green">Opslaan</button>
                                    </>
                                }
                                
                                
                            </div>
                        </div>
                        :
                        null
                    }
                </div>
                :
                null
            }
            <Toaster />
        </div>
        <BottomNav page="/prikbord" />
        <img src={svzArtwork} className="artwork" />
    </div>
    );
};
export default PrikbordItem;