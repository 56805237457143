import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import { GoogleMap, HeatmapLayer, LoadScript } from "@react-google-maps/api";


//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './heatmap.css';
import 'react-loading-skeleton/dist/skeleton.css';


const Heatmap = () => {

    //Set variables
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get('dossier');
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const containerStyle = {
        width: "100%",
        height: "800px",
    };
    const center = {
        lat: 52.6719884,
        lng: 5.0740646,
    };
    const [heatmapData, setHeatmapData] = useState([]);
    const libraries = ["visualization"];

    const getCoordinates = async () => {
        try {
          const response = await api.get("/schades/coordinates");
          if (response.data) {
            const mapData = response.data.map(
              (item) => new window.google.maps.LatLng(item.lat, item.lng)
            );
            setHeatmapData(mapData);
          }
        } catch (error) {
          console.error(error);
          if (!error.response) {
            return navigate("/offline");
          }
        }
      };

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (medewerker.gebruiker.role.type !== 'schadebeheer' && medewerker.gebruiker.role.type !== 'super_admin') {
            navigate('/');
        }
        getCoordinates();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/heatmap" />
            :
            null
        }
        <div className="dashboard-content">
            <DashboardHeader title={'Heatmap schades'} />
            
            <LoadScript
                googleMapsApiKey={'AIzaSyASACpMzJ3uqvqiUkW9flmlJWr4yNQnLqk'}
                libraries={libraries}
            >
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={8} mapTypeId='satellite'>
                    {heatmapData.length > 0 && <HeatmapLayer data={heatmapData} />}
                </GoogleMap>
            </LoadScript>

            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/heatmap" />
            :
            null
        }
    <img src={svzArtwork} className="artwork" />    
    </div>
    );
};
export default Heatmap;