import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//3rd party
import * as Sentry from "@sentry/react";

//Public pages
import Login from './pages/public/login/login';
import ResetPassword from './pages/public/reset-password/reset-password';
import Maintenance from './pages/public/maintenance/maintenance';
import Offline from './pages/public/offline/offline';

//Narrowcasting
import NarrowcastingLiggend from './pages/public/narrowcasting/narrowcasting-liggend';

//Authenticated pages
import Dashboard from './pages/authenticated/dashboard/dashboard';
import Declareren from './pages/authenticated/declareren/declareren';
import DeclaratiesControleren from './pages/authenticated/declareren/controleren';
import AlleDeclaraties from './pages/authenticated/declareren/alle-declaraties';
import Registreren from './pages/authenticated/registreren/registreren';
import RegistratiesControleren from './pages/authenticated/registreren/registraties-controleren';
import AlleRegistraties from './pages/authenticated/registreren/alle-registraties';
import Schades from './pages/authenticated/schades/schades';
import Gearchiveerd from './pages/authenticated/schades/gearchiveerd';
import Schade from './pages/authenticated/schade/schade';
import Kenteken from './pages/authenticated/kenteken/kenteken';
import Chauffeur from './pages/authenticated/chauffeur/chauffeur';
import Schadeformulier from './pages/authenticated/schadeformulier/schadeformulier';
import Wagenpark from './pages/authenticated/wagenpark/wagenpark';
import Werkbon from './pages/authenticated/wagenpark/werkbon';
import WerkbonReparateur from './pages/public/werkbon/werkbon';
import WerkbonAlgemeen from './pages/authenticated/werkbon-algemeen/werkbon-algemeen';
import SchadeRapportage from './pages/authenticated/schades/rapportage';
import Heatmap from './pages/authenticated/heatmap/heatmap';
import Prikbord from './pages/authenticated/prikbord/prikbord';
import PrikbordItem from './pages/authenticated/prikbord/prikborditem';
import NewPrikbordItem from './pages/authenticated/prikbord/new-prikborditem';
import Wiki from './pages/authenticated/wiki/wiki';
import Debiteuren from './pages/authenticated/dieselpercentages/debiteuren';
import DieselpercentagesVersturen from './pages/authenticated/dieselpercentages/dieselpercentages-versturen';
import Instellingen from './pages/authenticated/instellingen/instellingen';
import Menu from './pages/authenticated/menu/menu';

Sentry.init({
  dsn: "https://60bc3f219dfd16b5f485047b5b241c1e@o4506749669605376.ingest.us.sentry.io/4507451575959552",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      autoInject: false,
      showBranding: false,
      // Additional SDK configuration goes in here, for example:
      colorScheme: "themeLight",
      isNameRequired: true,
      isEmailRequired: true,
      useSentryUser: {
        name: "fullName",
        email: "email",
      },
      triggerLabel: "Probleem doorgeven?",
      formTitle: "Probleem doorgeven",
      submitButtonLabel: "Versturen",
      cancelButtonLabel: "Annuleren",
      confirmButtonLabel: "Bevestig",
      addScreenshotButtonLabel: "Screenshot toevoegen",
      removeScreenshotButtonLabel: "Verwijder screenshot",
      nameLabel: "Naam",
      namePlaceholder: "Naam",
      emailLabel: "E-mailadres",
      emailPlaceholder: "email@vsdv.nl",
      isRequiredLabel: "(verplicht)",
      messageLabel: "Omschrijving",
      messagePlaceholder: "Wat is het probleem?",
      successMessageText: "Bedankt voor je feedback!"
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <BrowserRouter>
    <Routes>
       <Route index element={<Dashboard/>} />
       <Route path="login" element={<Login/>} />
       <Route path="wachtwoordherstel" element={<ResetPassword/>} />
       <Route path="onderhoud" element={<Maintenance/>} />
       <Route path="offline" element={<Offline/>} />
       <Route path="declareren" element={<Declareren/>} />
       <Route path="declaraties-controleren" element={<DeclaratiesControleren/>} />
       <Route path="alle-declaraties" element={<AlleDeclaraties/>} />
       <Route path="registreren" element={<Registreren/>} />
       <Route path="uren-controleren" element={<RegistratiesControleren/>} />
       <Route path="alle-registraties" element={<AlleRegistraties/>} />
       <Route path="schades" element={<Schades/>} />
       <Route path="gearchiveerd" element={<Gearchiveerd/>} />
       <Route path="schades/:id" element={<Schade/>} />
       <Route path="schadeformulier" element={<Schadeformulier/>} />
       <Route path="kenteken/:kenteken" element={<Kenteken/>} />
       <Route path="chauffeur/:id" element={<Chauffeur/>} />
       <Route path="wagenpark" element={<Wagenpark/>} />
       <Route path="wagenpark/werkbon/:id" element={<Werkbon/>} />
       <Route path="werkbon-reparateur/:uuid" element={<WerkbonReparateur/>} />
       <Route path="werkbon-algemeen" element={<WerkbonAlgemeen/>} />
       <Route path="rapportage-schades" element={<SchadeRapportage/>} />
       <Route path="heatmap" element={<Heatmap/>} />
       <Route path="prikbord" element={<Prikbord/>} />
       <Route path="prikbord/:uuid" element={<PrikbordItem/>} />
       <Route path="prikbord/nieuw" element={<NewPrikbordItem/>} />
       <Route path="wiki" element={<Wiki/>} />
       <Route path="debiteuren" element={<Debiteuren/>} />
       <Route path="dieselpercentages-versturen" element={<DieselpercentagesVersturen/>} />
       <Route path="instellingen" element={<Instellingen/>} />
       <Route path="menu" element={<Menu/>} />
       <Route path="narrowcasting-liggend/:locatie" element={<NarrowcastingLiggend/>} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
