//API
import api from './AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';

//Function to use everywhere and get settings
export async function getSettings() {
    try {
        const response = await api.get('/instelling/');
        return response.data;
    } catch (error) {}
}

//Function to call videotutorials API
export async function getVideoTutorials() {
    try {
        const response = await api.get('/videotutorial');
        return response.data;
    } catch (error) {}
}

//Set notification functions
export const notifyError = (text) => toast.error(text, {
    position: "top-center"
});
export const notifySuccess = (text) => toast.success(text, {
    position: "top-center"
});

//Functions for calculating time
export const getTimeFromSeconds = (input) => {
    var sec_num = parseInt(input, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);
    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours + ':' + minutes;
}

export const getTotalSecondsFromTimeString = (timeString) => {
    if (!timeString) {
        return 0;
    }
    var a = timeString.split(':');
    const seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60;
    return seconds;
}

export const mapOveruren = (overuren) => {
    var seconds = 0;
    overuren.map((item, index) =>  {
        seconds += getTotalSecondsFromTimeString(item.diensturen_extra);
    })
    return seconds;
}

export const countTotalSeconds = (day) => {
    var seconds = getTotalSecondsFromTimeString(day.diensturen);
    seconds +=  mapOveruren(day.overuren);
    var secondsCorrectie = getTotalSecondsFromTimeString(day.correctie);
    return seconds - secondsCorrectie;
}

export const extractPlace = (arrayAddresses) => {
    const targetTypes = ["locality", "political"];
    const foundComponent = arrayAddresses.find(component => {
        return JSON.stringify(component.types) === JSON.stringify(targetTypes);
    });
    if (foundComponent) {
        return foundComponent.long_name;
    } else {
        return arrayAddresses[0].long_name;
    }
}

export async function handleDownload(folder, subfolder, filename) {
    api.get('/schades/get-file/' + folder + '/' + subfolder + '/' + filename, { responseType: 'blob' })
      .then(response => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('There was a problem with the axios request:', error);
      });
};

export async function getFileContents(folder, subfolder, filename) {
    api.get('/schades/get-file/' + folder + '/' + subfolder + '/' + filename, { responseType: 'blob' })
      .then(response => {
        const url = URL.createObjectURL(response.data);
        return url;
      })
      .catch(error => {
        console.error('There was a problem with the axios request:', error);
      });
}

export async function handleDownloadPrikbord(filename) {
    api.get('/prikbord/get-file/' + filename, { responseType: 'blob' })
      .then(response => {
        const url = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch(error => {
        if (error.response.status === 404) {
            notifyError('Bestand kan niet worden gevonden op de server');
        }
        console.error('There was a problem with the axios request:', error);
      });
};

export async function getFileContentsPrikbord(filename) {
    try {
        const response = await api.get('/prikbord/get-file/' + filename, { responseType: 'blob' });
        const url = URL.createObjectURL(response.data);
        const mimeType = response.headers['content-type'];
        const obj = {
            url: url,
            type: mimeType
        }
        return obj;
    } catch (error) {
        console.error('There was a problem with the axios request:', error);
        throw error; // Rethrow the error so the calling function can handle it
    }
}