import { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import { TailSpin } from  'react-loader-spinner';
import { saveAs } from "file-saver";
import Rodal from 'rodal';
import YouTube, { YouTubeProps } from 'react-youtube';
import EXIF from 'exif-js';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';
import DeclaratiesBlock from '../../../components/declaraties/declaratiesBlock';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './declareren.css';
import 'rodal/lib/rodal.css';

const Declareren = () => {

    //Set variables
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const videotutorials = JSON.parse(localStorage.getItem('videotutorials'));
    const [disabled, setDisabled] = useState(false);
    const [disabledText, setDisabledText] = useState('');
    const [disabledLink, setDisabledLink] = useState('');
    const [showModalHelp, setShowModalHelp] = useState(false);
    const naamMedewerker = medewerker.voornaam + ' ' + medewerker.achternaam;
    const fileMedewerker = naamMedewerker.split(' ').join('-');
    const [bedrag, setBedrag] = useState('');
    const [iban, setIban] = useState('');
    const [omschrijving, setOmschrijving] = useState('');
    const [datum, setDatum] = useState('');
    const [bestand, setBestand] = useState('');
    const [isKilometers, setIsKilometers] = useState(false);
    const [van, setVan] = useState('');
    const [naar, setNaar] = useState('');
    const [kilometers, setKilometers] = useState('');
    const [isRetour, setIsRetour] = useState(false);
    const [rekenfactor, setRekenfactor] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loadingDeclaraties, setLoadingDeclaraties] = useState(true);
    const [declaraties, setDeclaraties] = useState([]);
    const [declaratiesAfgekeurd, setDeclaratiesAfgekeurd] = useState([]);
    const [declaratiesGoedgekeurd, setDeclaratiesGoedgekeurd] = useState([]);
    const [declaratiesVerwerkt, setDeclaratiesVerwerkt] = useState([]);
    const [imageSrc, setImageSrc] = useState(null);
    const youtubePlayer = useRef(null);
    const opts = {
        height: '280',
        width: '500',
        playerVars: {
          autoplay: 0,
        },
    };

    const changeKilometers = () => {
        if (isKilometers) {
            setIsKilometers(false);
            setBedrag('');
        } else {
            setIsKilometers(true);
        }
    };

    const changeRetour = () => {
        if (isRetour) {
            setIsRetour(false);
            formatBedrag(kilometers * rekenfactor);
        } else {
            setIsRetour(true);
            formatBedrag((kilometers * 2) * rekenfactor);
        }
    };

    //Function to format bedrag to correct value
    const formatBedrag = (value) => {
        const formatted = Number(value).toFixed(2);
        setBedrag(formatted);
    }

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        setBestand(file);
    };

    //Function to get declaraties belonging to current medewerker (params ?me=true)
    const getDeclaraties = async () => {
        setLoadingDeclaraties(true);
        try {
            const response = await api.get('/declaraties?me=true');
            if (response.data) {
                const arrayDeclaraties = [];
                const arrayDeclaratiesAfgekeurd = [];
                const arrayDeclaratiesGoedgekeurd = [];
                const arrayDeclaratiesVerwerkt = [];
                response.data.map((item, index) =>  {
                    if (item.status === 'ingestuurd' || item.status === 'goedgekeurd') {
                        arrayDeclaraties.push(item);
                    } else if (item.status === 'verwerkt') {
                        arrayDeclaratiesVerwerkt.push(item);
                    } else if (item.status === 'goedgekeurd_def') {
                        arrayDeclaratiesGoedgekeurd.push(item);
                    }
                    else {
                        arrayDeclaratiesAfgekeurd.push(item);
                    }
                })
                setDeclaraties(arrayDeclaraties);
                setDeclaratiesAfgekeurd(arrayDeclaratiesAfgekeurd);
                setDeclaratiesGoedgekeurd(arrayDeclaratiesGoedgekeurd);
                setDeclaratiesVerwerkt(arrayDeclaratiesVerwerkt);
                setLoadingDeclaraties(false);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer eerst even opnieuw in te loggen.');
            }
        }
    }

    //Function to delete declaratie
    const deleteDeclaratie = async (id) => {
        if (disabled) {
            return notifyError('Declaratie verwijderen niet mogelijk');
        }
        setIsDeleting(true);
        try {
            const response = await toast.promise(
                api.delete('/declaraties/' + id),
                {
                    loading: 'Declaratie verwijderen...',
                    success: <b>Declaratie is verwijderd</b>,
                    error: <b>Er is iets misgegaan met het opslaan. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                setIsDeleting(false);
                getDeclaraties();
            }
        } catch (error) {}
    }

    //Function to create declaratie
    const createDeclaratie = async (id) => {
        if (disabled) {
            return notifyError('Declaratie indienen niet mogelijk');
        }
        try {
            const response = await toast.promise(
                api.post('/declaraties/', {
                    "data" : {
                        "bedrag": bedrag,
                        "iban": iban,
                        "kilometer_declaratie": id ? false : true,
                        "van": id ? '' : van,
                        "naar": id ? '' : naar,
                        "retour": id ? false : isRetour,
                        "kilometers": id ? 0 : Number(kilometers),
                        "datum": datum,
                        "omschrijving": omschrijving,
                        "bestand": id ? id : null
                    }
                }),
                {
                    loading: 'Declaratie aanmaken...',
                    success: <b>Declaratie succesvol toegevoegd</b>,
                    error: <b>Er is iets misgegaan met het toevoegen. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                setVan('');
                setNaar('');
                setKilometers('');
                setIsRetour(false);
                setBedrag('');
                setIban('');
                setOmschrijving('');
                setDatum('');
                setBestand('');
                if (bestand) {
                    document.getElementById('file-upload').value = "";
                }
                setIsLoading(false);
                getDeclaraties();
            }
        } catch (error) {}
    }

    const resendDeclaratie = async (declaratie) => {
        if (disabled) {
            return notifyError('Declaratie indienen niet mogelijk');
        }
        try {
            const response = await toast.promise(
                api.put('/declaraties/:id', {
                    "data" : {
                        "id": declaratie.id,
                        "status": "ingestuurd"
                    }
                }),
                {
                    loading: 'Declaratie opnieuw insturen...',
                    success: <b>Declaratie is opnieuw ingestuurd</b>,
                    error: <b>Er is iets misgegaan met het toevoegen. Probeer het later nog eens of neem contact op met ICT.</b>,
                }
            );
            if (response.data) {
                getDeclaraties();
            }
        } catch (error) {}
    }

    //Function to upload attached file and run function for declaratie
    const handleExpenses = async (event) => {
        event.preventDefault();
        if (!bedrag) {
            return notifyError('Vul een geldig bedrag in.');
        }
        if (!omschrijving) {
            return notifyError('Vul een omschrijving voor de onkosten in');
        }
        if (!datum) {
            return notifyError('Selecteer een datum');
        }
        const today = moment().format('YYYY-MM-DD');
        const isFuture = moment(datum).isAfter(today, 'day');
        if (isFuture) {
            return notifyError('Een datum in de toekomst is niet mogelijk bij een declaratie');
        }

        var formData = new FormData();
        formData.append("files", bestand);
        //Error if file size exceeds API capacity of 6MB
        if (bestand.size > 6291456) {
            return notifyError('De maximale bestandsgrootte van de bon is 6MB');
        }

        setIsLoading(true);

        if (bestand) {
            try {
                const response = await api.post('/upload', formData, {
                    headers: { 
                        "Content-Type": "multipart/form-data"
                    }
                });
                if (response.data) {
                    createDeclaratie(response.data[0].id);
                }
            } catch (error) {
                setIsLoading(false);
                switch(error.response.status) {
                    case 429:
                        notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                      break;
                    //Default for 400 
                    default:
                        notifyError('Er is iets fout gegaan (400). Probeer eerst even opnieuw in te loggen.');
                }
            }
        } else {
            createDeclaratie();
        }

    }

    //Check if user is authenticated and else redirect to login, else get declaraties
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            setRekenfactor(result.rekenfactor_km_declaratie);
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
            if (result.declaraties_uitschakelen) {
                setDisabledText(result.tekst_declaraties);
                setDisabledLink(result.link_afas);
                setDisabled(true);
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getDeclaraties();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/declareren" />
        <div className="dashboard-content">
            {
                disabled ?
                <div id="disabled-declarations">
                    <div className="info-box">
                        <div className="info-box-content">
                            <p>{disabledText}</p>
                            <a href={disabledLink} target="_blank">Ga naar AFAS</a>
                        </div>
                    </div>
                </div>
                :
                <>
                <DashboardHeader title="Declareren" />
                {
                    videotutorials.declareren ?
                    <button className="help-button" onClick={() => setShowModalHelp(true)}><Icon.QuestionCircle /><span>Help</span></button>
                    :
                    null
                }
                <div className="blocks">
                    <div className="block expenses">
                        <div className="content">
                        <div className="block-header"><h3>Onkosten indienen</h3><Icon.Receipt /></div>
                        <form id="expenses" className="expenses-form" onSubmit={handleExpenses}>
                            <label>
                                <input type="checkbox" checked={isKilometers} onChange={changeKilometers} />
                                Ik wil kilometers declareren
                            </label>
                            {
                                isKilometers ?
                                <div className="rit-details">
                                <h4>Ritgegevens</h4>
                                <Icon.CarFrontFill />
                                <div className="start-end">
                                <input type="text" name="van" placeholder="Van" value={van} onChange={(e) => setVan(e.target.value)} className="form-field" required={isKilometers} />
                                <input type="text" name="naar" placeholder="Naar" value={naar} onChange={(e) => setNaar(e.target.value)} className="form-field" required={isKilometers} />
                                </div>
                                <input type="number" min="1" step="1" placeholder="Aantal kilometers" value={kilometers} onChange={(e) => {setKilometers(e.target.value.replace(/[^0-9]*/g,'')); formatBedrag(isRetour ? ((e.target.value.replace(/[^0-9]*/g,'') * 2) * rekenfactor) : (e.target.value.replace(/[^0-9]*/g,'') * rekenfactor));}} className="form-field" required={isKilometers} />
                                <label>
                                    <input type="checkbox" checked={isRetour} onChange={changeRetour} />
                                    Dit is een retourrit
                                </label>
                                </div>
                                :
                                null
                            }
                            <input type="number" name="bedrag" placeholder={!isKilometers ? 'Bedrag' : 'Bedrag wordt berekend op basis van aantal km'} value={bedrag} min="0.01" step=".01" onChange={(e) => setBedrag(e.target.value)} onBlur={(e) => formatBedrag(e.target.value)} className="form-field" disabled={isLoading ? isLoading : isKilometers} required />
                            <input type="text" name="iban" placeholder="IBAN" value={iban} onChange={(e) => setIban(e.target.value)} className="form-field" disabled={isLoading} required />
                            <input type="text" name="omschrijving" placeholder="Omschrijving" value={omschrijving} onChange={(e) => setOmschrijving(e.target.value)} className="form-field" disabled={isLoading} required />
                            <input type="date" name="datum" placeholder="Datum (dd-mm-jjjj)" value={datum} onChange={(e) => setDatum(e.target.value)} className="form-field" disabled={isLoading} required />
                            {
                                !isKilometers ?
                                <div className="bestand">
                                    <span className="label">Foto/bon toevoegen (.JPG, .PNG, .PDF)</span>
                                    <input type="file" name="bestand" id="file-upload" placeholder="Bestand" accept="image/jpeg,image/png,application/pdf,image/x-eps" onChange={(e) => handleImageUpload(e)} className="form-field file" disabled={isLoading} required={!isKilometers} />
                                    {imageSrc && <img src={imageSrc} alt="uploaded" style={{maxWidth:500}} />}
                                </div>
                                :
                                null
                            }
                            
                            <div className="form-footer">
                            <input type="submit" value="Insturen" className="submit-form" disabled={isLoading} />
                            {
                                isLoading ?
                                <TailSpin
                                    height="30"
                                    width="30"
                                    color="#001c43"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                null
                            }
                            </div>
                        </form>
                        </div>
                    </div>
                    <div className="block pending-expenses">
                        <DeclaratiesBlock
                            title="Lopende declaraties"
                            declaraties={declaraties}
                            type="pending"
                            marginTop={0}
                            loadingDeclaraties={loadingDeclaraties}
                            deleteDeclaratie={deleteDeclaratie}
                            isDeleting={isDeleting}
                            fileMedewerker={fileMedewerker}
                            nothingFound="Er zijn geen lopende declaraties gevonden"
                        />

                        <DeclaratiesBlock
                            title="Afgewezen declaraties"
                            declaraties={declaratiesAfgekeurd}
                            type="rejected"
                            marginTop={50}
                            loadingDeclaraties={loadingDeclaraties}
                            deleteDeclaratie={deleteDeclaratie}
                            resendDeclaratie={resendDeclaratie}
                            isDeleting={isDeleting}
                            fileMedewerker={fileMedewerker}
                            nothingFound="Er zijn geen afgekeurde declaraties gevonden"
                        />

                        <DeclaratiesBlock
                            title="Goedgekeurde declaraties"
                            declaraties={declaratiesGoedgekeurd}
                            type="incorporated"
                            marginTop={50}
                            loadingDeclaraties={loadingDeclaraties}
                            deleteDeclaratie={deleteDeclaratie}
                            isDeleting={isDeleting}
                            fileMedewerker={fileMedewerker}
                            nothingFound="Er zijn geen goedgekeurde declaraties gevonden"
                        />

                        <DeclaratiesBlock
                            title="Verwerkte declaraties"
                            declaraties={declaratiesVerwerkt}
                            type="incorporated"
                            marginTop={50}
                            loadingDeclaraties={loadingDeclaraties}
                            deleteDeclaratie={deleteDeclaratie}
                            isDeleting={isDeleting}
                            fileMedewerker={fileMedewerker}
                            nothingFound="Er zijn geen verwerkte declaraties gevonden"
                        />
                        
                        <div className="info">
                            <Icon.InfoCircleFill />
                            <p>Verwerkte declaraties zullen 2 weken na de verwerking niet meer zichtbaar zijn.</p>
                        </div>

                    </div>
                </div>
                </>
            }
            <Rodal visible={showModalHelp} onClose={() => { setShowModalHelp(false); youtubePlayer.current.internalPlayer.pauseVideo(); }} width={500} enterAnimation="slideDown" leaveAnimation="slideUp" closeOnEsc={true}>
                <h3>Uitleg declareren</h3>
                <YouTube videoId={videotutorials.declareren} opts={opts} ref={youtubePlayer} />
            </Rodal>
            <Toaster />
        </div>
        <BottomNav page="/declareren" />
        <img src={svzArtwork} className="artwork" />
    </div>
    );
};
export default Declareren;