import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import toast, { Toaster } from 'react-hot-toast';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
  } from 'chart.js';
  import { Chart } from 'react-chartjs-2';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './rapportage.css';
import 'react-loading-skeleton/dist/skeleton.css';


const SchadeRapportage = () => {

    ChartJS.register(
        LinearScale,
        CategoryScale,
        BarElement,
        PointElement,
        LineElement,
        Legend,
        Tooltip,
        LineController,
        BarController
    );

    //Set variables
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const searchValue = searchParams.get('dossier');
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [year, setYear] = useState(moment().year());
    const [loadedYear, setLoadedYear] = useState(moment().year());

    const [lineChartData, setLineChartData] = useState({
        labels: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        datasets: [],
    });
    const [barChartDataSoort, setBarChartDataSoort] = useState({
        labels: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        datasets: [],
    });
    const [barChartType, setBarChartType] = useState({
        labels: ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        datasets: [],
    });

    const getRapportage = async () => {
        try {
            const response = await api.get("/schades/rapportage/" + year);
            if (response.data) {
                setLoadedYear(year);
                const { 
                    total_schades, 
                    total_schades_schuld, 
                    total_schades_charter, 
                    total_schades_letsel, 
                    total_schades_per_type, 
                    total_schades_per_soort 
                } = response.data;
    
                // Get the months that have data across all categories
                const availableMonths = [
                    ...new Set([
                        ...Object.keys(total_schades),
                        ...Object.keys(total_schades_schuld),
                        ...Object.keys(total_schades_charter),
                        ...Object.keys(total_schades_letsel)
                    ])
                ];
    
                // Prepare datasets for total_schades (Total damages)
                const datasetsLine = [
                    {
                        type: 'line',
                        label: 'Schades totaal',
                        data: availableMonths.map(month => total_schades[month] || 0),
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        fill: true,
                    },
                    {
                        type: 'line',
                        label: 'Schuldschades',
                        data: availableMonths.map(month => total_schades_schuld[month] || 0),
                        borderColor: 'rgb(54, 162, 235)',
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        fill: true,
                    },
                    {
                        type: 'line',
                        label: 'Charterschades',
                        data: availableMonths.map(month => total_schades_charter[month] || 0),
                        borderColor: 'rgb(75, 192, 192)',
                        backgroundColor: 'rgba(75, 192, 192, 0.5)',
                        fill: true,
                    },
                    {
                        type: 'line',
                        label: 'Letselschades',
                        data: availableMonths.map(month => total_schades_letsel[month] || 0),
                        borderColor: 'rgb(153, 102, 255)',
                        backgroundColor: 'rgba(153, 102, 255, 0.5)',
                        fill: true,
                    },
                ];
    
                // Update lineChartData state with available months
                setLineChartData((prev) => ({
                    ...prev,
                    datasets: datasetsLine,
                    labels: availableMonths,  // Set the months as labels
                }));
    
                // Add datasets for total_schades_per_type
                const datasetsBarSoort = [];
                const labelsSoort = Object.keys(total_schades_per_type[availableMonths[0]] || {}); // Using the first available month
    
                for (const type of labelsSoort) {
                    const data = availableMonths.map(month => total_schades_per_type[month]?.[type] || 0);
                    const randomColor = getRandomColor(1);
    
                    datasetsBarSoort.push({
                        type: 'bar',
                        label: type,
                        data,
                        borderColor: randomColor,
                        backgroundColor: randomColor,
                    });
                }
    
                // Update barChartDataSoort state
                setBarChartDataSoort({
                    labels: availableMonths,
                    datasets: datasetsBarSoort,
                });
    
                // Add datasets for total_schades_per_soort
                const datasetsBartype = [];
                const labelsBar = Object.keys(total_schades_per_soort[availableMonths[0]] || {});
    
                for (const category of labelsBar) {
                    const data = availableMonths.map(month => total_schades_per_soort[month]?.[category] || 0);
                    const randomColor = getRandomColor(1);
    
                    datasetsBartype.push({
                        type: 'bar',
                        label: category,
                        data,
                        borderColor: randomColor,
                        backgroundColor: randomColor,
                    });
                }
    
                // Update barChartType state
                setBarChartType({
                    labels: availableMonths,
                    datasets: datasetsBartype,
                });
    
            }
        } catch (error) {
            if (!error.response) {
                return navigate("/offline");
            }
        }
    };

    const submitFilters = (event) => {
        event.preventDefault();
        if (year.length !== 4) {
            return notifyError("Vul een geldig jaar in");
        }
        getRapportage();
    }
    
    
    
    // Utility function to generate random colors
    const getRandomColor = (opacity = 1) => {
        const r = Math.floor(Math.random() * 256);
        const g = Math.floor(Math.random() * 256);
        const b = Math.floor(Math.random() * 256);
        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    };
    
    

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (medewerker.gebruiker.role.type !== 'schadebeheer' && medewerker.gebruiker.role.type !== 'super_admin') {
            navigate('/');
        }
        getRapportage();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/rapportage-schades" />
            :
            null
        }
        <div className="dashboard-content">
            <DashboardHeader title={'Rapportage schades ' + loadedYear} />

            <div className="rapportage-filters">
                <form onSubmit={submitFilters}>
                    <input type="number" placeholder="Jaar" value={year} onChange={(e) => setYear(e.target.value)} required />
                    <button type="submit" className="button">Filter</button>
                </form>
                
            </div>
            
            {lineChartData.datasets.length > 0 ? (
                <Chart type='bar' data={lineChartData} />
            ) : (
                <p>Laden...</p>
            )}

            {barChartDataSoort.datasets.length > 0 ? (
                <Chart type='bar' data={barChartDataSoort} />
            ) : (
                <p>Laden...</p>
            )}

            {barChartType.datasets.length > 0 ? (
                <Chart type='bar' data={barChartType} />
            ) : (
                <p>Laden...</p>
            )}

            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/rapportage-schades" />
            :
            null
        }
    <img src={svzArtwork} className="artwork" />    
    </div>
    );
};
export default SchadeRapportage;