import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import * as Icon from 'react-bootstrap-icons';
import toast, { Toaster } from 'react-hot-toast';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import DataTable from 'react-data-table-component';
import exportFromJSON from 'export-from-json';
import { exportToExcel } from 'react-json-to-excel';
import { Tooltip } from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './schades.css';
import 'react-loading-skeleton/dist/skeleton.css';

//Inline components
const Datum = (props) => <div><p>{props.datum}</p></div>
const Kenteken = (props) => <div><p onClick={() => props.navigate('/kenteken/' + props.kenteken)} className="link-kenteken">{props.kenteken}</p></div>
const Chauffeur = (props) => <div><p onClick={() => props.navigate('/chauffeur/' + props.id)} className={'link-kenteken ' + (props.aantalSchades > 2 ? 'red' : '')}>{props.chauffeur} ({props.aantalSchades})</p></div>
const Status = (props) => <div><p style={{color: props.status.kleur}}>{props.status.naam}</p></div>
const Schuldschade = (props) => <div><p className={props.schuldschade ? 'red' : ''}>{props.schuldschade ? 'Ja' : 'Nee'}</p></div>
const Actions = (props) => <div className="actions schade-actions">
    <button onClick={() => props.navigate('/schades/' + props.id)} className="red-button button view-dossier">Bekijken</button>
    {
        props.status.key === 'in_afwachting_reparateur' ?
        <>
            <span className="copy-url-werkbon" id={'copy-url-' + props.id} onClick={() => { notifySuccess('Link naar werkbon gekopieërd'); navigator.clipboard.writeText('https://personeel.svztransport.nl/werkbon-reparateur/' + props.uuid); }}><Icon.Clipboard /></span>
            <Tooltip
                anchorId={'copy-url-' + props.id}
                place="top"
                content="Werkbon link kopiëren"
            />
        </>
        :
        null
    }
    <>
        <span className="delete-schade" id={'delete-' + props.id} onClick={() => { props.confirmDeleteSchade(props.id) }}><Icon.Trash3 /></span>
        <Tooltip
            anchorId={'delete-' + props.id}
            place="top"
            content="Schade verwijderen"
        />
    </>
    </div>

const Gearchiveerd = () => {

    //Set variables
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const from_dossier = queryParams.get('from_dossier');
    const searchValue = localStorage.getItem('filterText');
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const [soortenVervoer, setSoortenVervoer] = useState([]);
    const [soortVervoer, setSoortVervoer] = useState('');
    const [divisies, setDivisies] = useState([]);
    const [divisie, setDivisie] = useState('');
    const [bedrijven, setBedrijven] = useState([]);
    const [bedrijf, setBedrijf] = useState('');
    const [schadeType, setSchadeType] = useState('');
    const [schadeTypes, setSchadeTypes] = useState('');
    const [standplaatsen, setStandplaatsen] = useState([]);
    const [standplaats, setStandplaats] = useState('');
    const [exportMonth, setExportMonth] = useState(moment().month());
    const [months, setMonths] = useState([
        {
            value: 0,
            name: 'Januari'
        },
        {
            value: 1,
            name: 'Februari'
        },
        {
            value: 2,
            name: 'Maart'
        },
        {
            value: 3,
            name: 'April'
        },
        {
            value: 4,
            name: 'Mei'
        },
        {
            value: 5,
            name: 'Juni'
        },
        {
            value: 6,
            name: 'Juli'
        },
        {
            value: 7,
            name: 'Augustus'
        },
        {
            value: 8,
            name: 'September'
        },
        {
            value: 9,
            name: 'Oktober'
        },
        {
            value: 10,
            name: 'November'
        },
        {
            value: 11,
            name: 'December'
        }
    ]);
    const [exportYear, setExportYear] = useState(moment().format('YYYY'));
    const [exportStandplaats, setExportStandplaats] = useState('');
    const [schades, setSchades] = useState([]);
    const [loadingSchades, setLoadingSchades] = useState(true);
    const [filterText, setFilterText] = useState('');

    //Function to sort on string value
    const filteredData = schades.filter((item) => {
        const columnsToSearch = ['kenmerk', 'kenmerk_tvm', 'datum', 'kenteken', 'chauffeur', 'plaats', 'status']; // Specify the columns you want to include in the search
        for (let i = 0; i < columnsToSearch.length; i++) {
          const column = columnsToSearch[i];
          if (column === 'datum') {
            var cellData = item[column].props.datum.toLowerCase();
          } else if (column === 'kenteken') {
            var cellData = item[column].props.kenteken.toLowerCase();
          } else if (column === 'kenmerk_tvm') {
            if (item[column]) {
                var cellData = item[column].toLowerCase();
            }
          } else if (column === 'status') {
            var cellData = item[column].props.status.naam.toLowerCase();
          } else if (column === 'chauffeur') {
            var cellData = item[column].props.chauffeur.toLowerCase();
          } else {
            var cellData = item[column].toString().toLowerCase();
          }
          if (cellData.includes(filterText.toLowerCase())) {
            return true; // Return true if any column matches the search text
          }
        }
        return false; // Return false if no columns match the search text
    });

    //Function to do custom sort on timestamp instead of datestring
    const customDateSort = (rowA, rowB) => {
        const a = rowA.datum.props.sortDate;
        const timeStampA = new Date(a).getTime();
        const b = rowB.datum.props.sortDate;
        const timeStampB = new Date(b).getTime();
        if (timeStampA > timeStampB) {
            return 1;
        }
        if (timeStampB > timeStampA) {
            return -1;
        }
        return 0;
    };

    //Function to do custom sort on number based bedrag instead of string
    const customStatusSort = (rowA, rowB) => {
        const a = rowA.status.props.id;
        const b = rowB.status.props.id;
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    const customKentekenSort = (rowA, rowB) => {
        const a = rowA.kenteken.props.sortKenteken;
        const b = rowB.kenteken.props.sortKenteken;
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    }

    //Columns for datatable
    const columns = [
        {
            name: 'Kenteken',
            selector: row => row.kenteken,
            sortable: true,
            hide: 'md',
            sortFunction: customKentekenSort,
            maxWidth: '120px'
        },
        {
            name: 'Trailer/aanhanger',
            selector: row => row.kenteken_trailer_aanhanger,
            sortable: true,
            hide: 'md',
            maxWidth: '120px'
        },
        {
            name: 'Kenmerk TVM',
            selector: row => row.kenmerk_tvm,
            sortable: true,
            maxWidth: '120px'
        },
        {
            name: 'Datum',
            selector: row => row.datum,
            sortable: true,
            sortFunction: customDateSort,
            maxWidth: '110px'
        },
        {
            name: 'Soort schade',
            selector: row => row.soort_schade,
            sortable: true,
            maxWidth: '130px'
        },
        {
            name: 'Kenmerk',
            selector: row => row.kenmerk,
            sortable: true,
            maxWidth: '100px'
        },
        {
            name: 'Chauffeur',
            selector: row => row.chauffeur,
            sortable: true,
            minWidth: '200px'
        },
        {
            name: 'Verhaal/eigen',
            selector: row => row.verhaal_eigen,
            sortable: true,
        },
        // {
        //     name: 'Schuld',
        //     selector: row => row.schuldschade,
        //     sortable: true,
        //     hide: 'md',
        //     maxWidth: '50px'
        // },
        {
            name: 'Plaats',
            selector: row => row.plaats,
            sortable: true,
            hide: 'md',
            maxWidth: '120px'
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            sortFunction: customStatusSort,
            hide: 'md',
            minWidth: '200px'
        },
        {
            name: 'Acties',
            selector: row => row.acties,
            minWidth: '170px'
        }
    ];
    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };

    const getSchades = async () => {
        setLoadingSchades(true);
        try {
            const response = await api.post('/schades/filter', {
                data: {
                    soort_vervoer: soortVervoer,
                    bedrijf: bedrijf,
                    schadetype: schadeType,
                    standplaats: standplaats
                }
            });
            if (response.data) {
                var arrayData = [];
                response.data.map((item, index) =>  {
                    if (item.status.key === 'gearchiveerd') {
                        const diffInDays = moment().diff(item.updatedAt, 'days');
                        const obj = {
                            id: item.id,
                            kenmerk: item.kenmerk,
                            kenmerk_tvm: item.kenmerk_tvm,
                            datum: <Datum 
                                sortDate={item.datum}
                                datum={moment(item.datum).format('DD-MM-YYYY')}
                            />,
                            soort_schade: item.soort_schade,
                            kenteken: <Kenteken 
                                sortKenteken={item.kenteken ? item.kenteken.replace(/-/g, "") : ''}
                                kenteken={item.kenteken ? item.kenteken : ''}
                                navigate={navigate}
                            />,
                            kenteken_trailer_aanhanger: <Kenteken 
                                sortKenteken={item.kenteken_trailer_aanhanger? item.kenteken_trailer_aanhanger.replace(/-/g, "") : ''}
                                kenteken={item.kenteken_trailer_aanhanger ? item.kenteken_trailer_aanhanger : ''}
                            />,
                            chauffeur: <Chauffeur 
                                chauffeur={item.chauffeur_medewerker ? item.chauffeur_medewerker.naam : item.charter.naam}
                                aantalSchades={item.count_chauffeur_medewerker_schades}
                                id={item.chauffeur_medewerker ? item.chauffeur_medewerker.id : item.charter.id}
                                navigate={navigate}
                                medewerker={medewerker}
                            />,
                            verhaal_eigen: item.verhaal_eigen ? item.verhaal_eigen === 'verhaal' ? 'Verhaal' : 'Eigen' : '',
                            schuldschade: <Schuldschade schuldschade={item.schuldschade} />,
                            plaats: item.plaats.naam,
                            status: <Status status={item.status} id={item.status.id} />,
                            acties: <Actions id={item.id} status={item.status} uuid={item.uuid} confirmDeleteSchade={confirmDeleteSchade} navigate={navigate} />,
                            updated_days_ago: diffInDays
                        };
                        arrayData.push(obj);
                    }
                })
                setSchades(arrayData);
                setLoadingSchades(false);
                if (searchValue && from_dossier) {
                    setFilterText(searchValue);
                }
            }
        } catch (error) {
            console.log(error);
            if (!error.response) {
                return navigate('/offline');
            }
        }
    }

    const getBedrijven = async () => {
        try {
            const response = await api.get('/schades/bedrijven' );
            if (response.data) {
                const arrayBedrijven = [];
                response.data.map((item, index) =>  {
                    const obj = {
                        value: item.companyNr,
                        label: item.name
                    };
                    arrayBedrijven.push(obj);
                })
                setBedrijven(arrayBedrijven);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteSchade = async (id) => {
        try {
            const response = await api.delete('/schades/' + id );
            if (response.data) {
                notifySuccess('Schade is permanent verwijderd');
                getSchades();
            }
        } catch (error) {
            console.log(error);
        }
    }

    const confirmDeleteSchade = (id) => {
        confirmAlert({
            title: 'Weet je het zeker?',
            message: 'Hiermee wordt de schade permanent verwijderd en kun je deze niet meer terug halen?',
            buttons: [
              {
                label: 'Ja',
                onClick: () => deleteSchade(id)
              },
              {
                label: 'Nee'
              }
            ]
          });
    }

    const getOptions = async (route, state) => {
        const itemsArray = [];
        try {
            const response = await api.get(route);
            if (response.data) {
                response.data.data.map((item, index) =>  {
                    const arrayItem = {
                        id: item.id,
                        name: item.attributes.naam
                    };
                    itemsArray.push(arrayItem);
                });
                state(itemsArray);
            }
        } catch (error) {}
    }

    const downloadCsv = (data) => {
        var fileName = 'export-schades-' + moment().month(exportMonth).format('MMMM') + '-' + exportYear;
        exportToExcel(data, fileName)
        //const exportType =  exportFromJSON.types.xls
        //exportFromJSON({ data, fileName, exportType })
    }

    const exportSchades = async (event) => {
        event.preventDefault();
        try {
            const response = await api.post('/schades/export', {
                "data": {
                    "month": exportMonth,
                    "year": exportYear,
                    "standplaats": exportStandplaats,
                    "status": 'gearchiveerd'
                }
            });
            if (response.data) {
                if (response.data.length === 0) {
                    return notifyError('Er zijn geen schades gevonden op de gekozen data')
                }
                downloadCsv(response.data, 'maand');
            }
        } catch (error) {}
    }

    //Check if user is authenticated, else redirect to login
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        if (medewerker.gebruiker.role.type !== 'schadebeheer' && medewerker.gebruiker.role.type !== 'super_admin') {
            navigate('/');
        }
        getSchades();
        getBedrijven();
        getOptions('/divisies?sort[0]=naam', setDivisies);
        getOptions('/soorten-vervoers?sort[0]=naam', setSoortenVervoer);
        getOptions('/schadetypes?sort[0]=naam', setSchadeTypes);
        getOptions('/standplaatsen?sort[0]=naam', setStandplaatsen);
    }, []);
    
    return (
    <div id="main" className="dashboard">
        {
            medewerker ?
            <SideNav page="/gearchiveerd" />
            :
            null
        }
        
        <div className="dashboard-content">
            <DashboardHeader title="Gearchiveerde schades" />

            <div className="controleren-header schadeoverzicht">
                    <div className="dropdowns">
                    {
                            soortenVervoer.length > 0 ?
                            <select defaultValue={soortVervoer} onChange={e => setSoortVervoer(e.target.value)}>
                                <option value="">Alle soorten vervoer</option>
                                {
                                     soortenVervoer.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        {
                            bedrijven.length > 0 ?
                            <select defaultValue={bedrijf} onChange={e => setBedrijf(e.target.value)}>
                                <option value="">Alle bedrijven</option>
                                {
                                     bedrijven.map((item, index) =>  {
                                        return <option key={index} value={item.value}>{item.label}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        {
                            schadeTypes.length > 0 ?
                            <select defaultValue={schadeType} onChange={e => setSchadeType(e.target.value)}>
                                <option value="">Alle schadetypes</option>
                                {
                                     schadeTypes.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        {
                            standplaatsen.length > 0 ?
                            <select defaultValue={standplaats} onChange={e => setStandplaats(e.target.value)}>
                                <option value="">Alle standplaatsen</option>
                                {
                                     standplaatsen.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        <button disabled={loadingSchades} onClick={() => getSchades()}>Schades ophalen</button>
                    </div>
                    <div className="schade-actions">
                    <input
                        type="text"
                        value={filterText}
                        onChange={(e) => {setFilterText(e.target.value); localStorage.setItem("filterText", e.target.value);}}
                        placeholder="Zoeken..."
                    />
                    <form id="export-schades" onSubmit={exportSchades}>
                        {
                            months.length > 0 ?
                            <select defaultValue={exportMonth} onChange={e => setExportMonth(e.target.value)} required>
                                {
                                     months.map((item, index) =>  {
                                        return <option key={index} value={item.value}>{item.name}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        {
                            standplaatsen.length > 0 ?
                            <select defaultValue={exportStandplaats} onChange={e => setExportStandplaats(e.target.value)}>
                                <option value="">Alle standplaatsen</option>
                                {
                                     standplaatsen.map((item, index) =>  {
                                        return <option key={index} value={item.id}>{item.name}</option>
                                     })
                                }
                            </select>
                            :
                            null
                        }
                        <input type="number" placeholder="Jaar" className="year" value={exportYear} onChange={(e) => setExportYear(e.target.value)} required />
                        <input type="submit" value="Exporteren" className="button" />
                    </form>
                    </div>
                </div>

            {
                loadingSchades ?
                <>
                <Skeleton height={40} style={{marginBottom:10,marginTop:20}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                <Skeleton height={40} style={{marginBottom:10}} />
                </>
                :
                <>
                {
                    schades.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        noDataComponent={<p>Geen schades gevonden...</p>}
                        fixedHeader
                        paginationPerPage={100}
                    />
                    :
                    <p>Er zijn geen schades gevonden.</p>
                }
                </>
            }

            <Toaster />
        </div>
        {
            medewerker ?
            <BottomNav page="/gearchiveerd" />
            :
            null
        }
    <img src={svzArtwork} className="artwork" />    
    </div>
    );
};
export default Gearchiveerd;