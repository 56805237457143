import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";

//Helper functions
import { getSettings, notifyError, notifySuccess, handleDownloadPrikbord } from '../../../settings/Helpers';

//API
import api from '../../../settings/AxiosSetup';

//3rd party
import toast, { Toaster } from 'react-hot-toast';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from 'react-loading-skeleton';
import 'moment/locale/nl';
import moment from 'moment';
import 'moment-timezone';
import Select from 'react-select';
import { TailSpin } from  'react-loader-spinner';

//Components
import SideNav from '../../../components/sidenav/sidenav';
import BottomNav from '../../../components/bottomnav/bottomnav';
import DashboardHeader from '../../../components/dashboard/header';

//Assets
import vsdvArtwork from '../../../assets/images/logo-detail.svg';
import svzArtwork from '../../../assets/images/artwork-svz.svg';

//styles
import './prikbord.css';
import 'rodal/lib/rodal.css';
import 'react-pulse-dot/dist/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

const NewPrikbordItem = () => {

    //Set variables
    const { uuid } = useParams();
    const navigate = useNavigate();
    const loggedIn = localStorage.getItem('ingelogd');
    const medewerker = JSON.parse(localStorage.getItem('medewerker'));
    const environment = process.env.REACT_APP_APP_URL;
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState('');
    const [medewerkerOptions, setMedewerkerOptions] = useState([]);
    const [medewerkers, setMedewerkers] = useState([
        {
            value: '',
            label: ''
        }
    ]);
    const [isCreating, setIsCreating] = useState(false);

    //Translations for datatable
    const paginationComponentOptions = {
        rowsPerPageText: 'Rijen per pagina',
        rangeSeparatorText: 'van',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Selecteer alles',
    };    

    //Function to get declaraties
    const getMedewerkers = async () => {
        try {
            const response = await api.post('/prikbord/medewerkers');
            if (response.data) {
                setMedewerkerOptions(response.data);
            }
        } catch (error) {
            if (!error.response) {
                return navigate('/offline');
            }
            notifyError(error.response.data.error);
        }
    }

    const createNewItem = async (event) => {
        event.preventDefault();
        const hasValue = medewerkers.some(item => item.value !== '');
        if (!hasValue) {
            return notifyError('Vul tenminste 1 medewerker in');
        }
        setIsCreating(true);
        let fileName = '';
        if (file) {
            fileName = await uploadDocument();
        }
        try {
            const response = await api.post('/prikborden', {
                data: {
                    title: title,
                    description: description,
                    file: fileName,
                    medewerkers: medewerkers
                }
            });
            if (response.data) {
                setTitle('');
                setDescription('');
                setFile('');
                setMedewerkers([
                    {
                        value: '',
                        label: ''
                    }
                ]);
                notifySuccess('Prikbord item is succesvol aangemaakt');
                setIsCreating(false);
            }
        } catch (error) {
            notifyError('Er is iets mis gegaan');
            setIsCreating(false);
        }
    }

    const handleChange = (index, value) => {
        const current = [...medewerkers];
        current[index] = value;
        setMedewerkers(current);
    }
    const handleMedewerkers = (type, index) => {
        const current = [...medewerkers];
        if (type === 'add') {
            current.push({value: '', label: ''});
        } else {
            current.splice(index, 1);
        }
        setMedewerkers(current);
    }

    const uploadDocument = async () => {
        var formData = new FormData();
        formData.append("files", file);
        if (file.size > 52428800) {
            return notifyError('De maximale bestandsgrootte van de bijlage is 50MB');
        }
        try {
            const response = await api.post('/prikbord/upload-file', formData, {
                headers: { 
                    "Content-Type": "multipart/form-data"
                }
            });
            if (response.data) {
                return response.data.filenames[0];
            }
        } catch (error) {
            switch(error.response.status) {
                case 429:
                    notifyError('Er is iets fout gegaan (429). Probeer het later nog eens of neem contact op met ICT.');
                  break;
                //Default for 400 
                default:
                    notifyError('Er is iets fout gegaan (400). Probeer eerst even opnieuw in te loggen.');
            }
        }
    }

    //Check some things on page load and redirect if not authenticated
    useEffect(() => {
        //Get settings and check for maintenance
        const settings = getSettings();
        settings.then((result) => {
            if (result.onderhoudsmodus) {
                navigate('/onderhoud');
            }
        });
        if (loggedIn !== 'true') {
            navigate('/login');
        }
        getMedewerkers();
    }, []);
    
    return (
    <div id="main" className="dashboard">
        <SideNav page="/prikbord/nieuw" />
        <div className="dashboard-content">
            <DashboardHeader title={'Prikbord item aanmaken'} />
            <div className="prikbord-columns">
                <div className="column">
                    <div className="column-content">
                        <h3>Informatie prikbord item</h3>
                        <form id="create-prikbord" onSubmit={createNewItem}>
                            <input type="text" placeholder="Titel" className="form-field" value={title} onChange={(e) => setTitle(e.target.value)} required disabled={isCreating} />
                            <textarea placeholder="Omschrijving" className="form-field" rows="10" value={description} onChange={(e) => setDescription(e.target.value)} required disabled={isCreating}></textarea>
                            <div className="bestand">
                                <span className="label">Bestand toevoegen (.JPG, .PNG, .PDF)</span>
                                <input type="file" name="bestand" id="file-upload" placeholder="Bestand" accept="image/jpeg,image/png,application/pdf,image/x-eps" onChange={(e) => setFile(e.target.files[0])} className="form-field file" disabled={isCreating} />
                            </div>
                            <p>Medewerkers:</p>
                            {
                                medewerkers.map((item, index) =>  {
                                    return (
                                        <div className="medewerker-row">
                                        <div className="field">
                                            <Select
                                                key={index} 
                                                options={medewerkerOptions} 
                                                className={'select-option'} 
                                                placeholder="Medewerker" 
                                                value={item} 
                                                onChange={(e) => handleChange(index, e)}
                                            />
                                        </div>
                                        <div className="buttons">
                                            {
                                                medewerkers.length > 1 ?
                                                <span className="remove" onClick={() => handleMedewerkers('remove', index)}><Icon.DashCircleFill /></span>
                                                :
                                                null
                                            }
                                            <span className="add" onClick={() => handleMedewerkers('add', index)}><Icon.PlusCircleFill /></span>
                                        </div>
                                        </div>
                                    )
                                })
                            }
                            {
                                isCreating ?
                                <TailSpin
                                    height="30"
                                    width="30"
                                    color="#001c43"
                                    ariaLabel="tail-spin-loading"
                                    radius="1"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                />
                                :
                                <input type="submit" value="Aanmaken" className="create-prikbord" disabled={isCreating} />
                            }
                            
                        </form>
                    </div>
                </div>
            </div>
            
            <Toaster />
        </div>
        <BottomNav page="/prikbord/nieuw" />
        <img src={svzArtwork} className="artwork" />
    </div>
    );
};
export default NewPrikbordItem;